import { render, staticRenderFns } from "./SecurityInfoCardSkeleton.vue?vue&type=template&id=5a4c4e3a&scoped=true&"
import script from "./SecurityInfoCardSkeleton.vue?vue&type=script&lang=js&"
export * from "./SecurityInfoCardSkeleton.vue?vue&type=script&lang=js&"
import style0 from "./SecurityInfoCardSkeleton.vue?vue&type=style&index=0&id=5a4c4e3a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a4c4e3a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCol,VSkeletonLoader})
